import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {nl2br} from "react-js-nl2br";

import {useGetAnime} from "./hooks";
import {LoadingScreen, Navigation} from "@src/components";
import {useBodyDarker} from "@src/hooks";
import * as DateUtils from "@src/utils/date.utils";

const DetailsAnime = () => {
    // L'id de l'anime passé en paramètre
    const {id} = useParams();
    // L'anime de la page courante
    const {anime} = useGetAnime(id);
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();

    // Permet d'ajouter la classe "bg-darker" au body et à root lors de la visite de cette page
    useBodyDarker();

    return (
        <>
            <Navigation/>
            {!anime ? (
                <LoadingScreen increase={true} />
            ) : (
                <section className="bg-text">
                    <div className="bg-side-image"
                         style={{backgroundImage: `url(/img/anime/${anime.id}/${anime.image})`}}>
                    </div>
                    <section className="absolute-top-full-width split-content" id="container-anime">
                        <div className="left">
                            <div className="content-left">
                                <div className="display-img"
                                     style={{backgroundImage: `url(/img/anime/${anime.id}/card/${anime.carte_image}), url(/img/default-card-img.jpg)`}}
                                />
                                {anime.genres &&
                                    <div className="genres">
                                        {(anime.genres).map((genre, index) => (
                                            <div key={index}>
                                                <span onClick={() => navigate(`/genres/${genre.id}`)}>
                                                    {genre.libelle}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="right">
                            <h1>{anime.libelle}</h1>
                            <p className="synopsis">{nl2br(anime.synopsis)}</p>
                            <div className="content-right">
                                <div className="bloc-title">
                                    <h3>Date de visionnage :</h3>
                                    <span>{DateUtils.formatLitteralDate(anime.premier_visionnage)}</span>
                                </div>
                                <div className="bloc-title">
                                    <h3>Nombre de saison :</h3>
                                    <span>{anime.nb_saison > 0 ? anime.nb_saison : 'Film'}</span>
                                </div>
                                <div className="bloc-title">
                                    <h3>Avis :</h3>
                                    <span>{anime.note.libelle}</span>
                                </div>
                                <div className="bloc-title">
                                    <h3>Suite :</h3>
                                    <span>{anime.suite.libelle}</span>
                                </div>
                                <div className="bloc-title">
                                    <h3>Saison :</h3>
                                    <span>N/C</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            )}
        </>
    );
}

export default DetailsAnime;